// import PlacesAutocomplete from "stimulus-places-autocomplete";

// original source can be found here https://github.com/stimulus-components/stimulus-places-autocomplete/blob/master/src/index.ts
// adjusted to match CRM fields of Address model

// https://www.stimulus-components.com/docs/stimulus-places-autocomplete

import { Controller } from "@hotwired/stimulus";

interface Address {
  street_number: string;
  route: string;
  locality: string;
  administrative_area_level_2: string;
  administrative_area_level_1: string;
  country: string;
  postal_code: string;
}

export default class extends Controller {
  autocomplete: google.maps.places.Autocomplete;
  place: google.maps.places.PlaceResult;

  fullAddressTarget: HTMLInputElement;
  fullAddressFormattedTarget: HTMLInputElement;
  streetNumberTarget: HTMLInputElement;
  streetTarget: HTMLInputElement;
  routeTarget: HTMLInputElement;
  cityTarget: HTMLInputElement;
  countyTarget: HTMLInputElement;
  stateTarget: HTMLInputElement;
  countryTarget: HTMLInputElement;
  zipcodeTarget: HTMLInputElement;
  longitudeTarget: HTMLInputElement;
  latitudeTarget: HTMLInputElement;

  hasStreetNumberTarget: boolean;
  hasRouteTarget: boolean;
  hasCityTarget: boolean;
  hasCountryTarget: boolean;
  hasCountyTarget: boolean;
  hasPostalCodeTarget: boolean;
  hasStateTarget: boolean;
  hasLongitudeTarget: boolean;
  hasLatitudeTarget: boolean;

  countryValue: Array<string>;

  static targets = [
    "fullAddress",
    "fullAddressFormatted",
    "city",
    "streetNumber",
    "street",
    "route",
    "zipcode",
    "country",
    "county",
    "state",
    "longitude",
    "latitude",
  ];

  static values = {
    country: Array,
  };

  initialize(): void {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect(): void {
    if (typeof google !== "undefined") {
      this.initAutocomplete();
    }
  }

  initAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.fullAddressTarget,
      this.autocompleteOptions
    );

    this.autocomplete.addListener("place_changed", this.placeChanged);
  }

  placeChanged(): void {
    this.place = this.autocomplete.getPlace();
    const addressComponents: google.maps.GeocoderAddressComponent[] =
      this.place.address_components;

    if (addressComponents !== undefined) {
      const formattedAddress = this.formatAddressComponents(
        addressComponents
      ) as Address;

      this.setAddressComponents(formattedAddress);
    }

    this.fullAddressFormattedTarget.value = this.place.formatted_address;

    if (this.place.geometry !== undefined) {
      this.setGeometry(this.place.geometry);
    }
  }

  setAddressComponents(address: Address): void {
    this.cityTarget.value = address.locality;
    this.countryTarget.value = address.country;
    this.countyTarget.value = address.administrative_area_level_2;
    this.stateTarget.value = address.administrative_area_level_1;
    this.streetTarget.value = address.street_number + " " + address.route;
    this.zipcodeTarget.value = address.postal_code;
  }

  setGeometry(geometry: google.maps.places.PlaceGeometry): void {
    if (this.hasLongitudeTarget)
      this.longitudeTarget.value = geometry.location.lng().toString();
    if (this.hasLatitudeTarget)
      this.latitudeTarget.value = geometry.location.lat().toString();
  }

  get autocompleteOptions(): google.maps.places.AutocompleteOptions {
    return {
      fields: ["address_components", "geometry", "url", "formatted_address"],
      componentRestrictions: {
        country: this.countryValue,
      },
    };
  }

  preventSubmit(event: KeyboardEvent): void {
    if (event.code === "Enter") {
      event.preventDefault();
    }
  }

  private formatAddressComponents(
    addressComponents: google.maps.GeocoderAddressComponent[]
  ): Address {
    const data = {};

    addressComponents.forEach(
      (component: google.maps.GeocoderAddressComponent) => {
        const type = component.types[0];

        data[type] = component.long_name;
      }
    );

    return data as Address;
  }
}
