import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toRemove"];

  remove(evt) {
    evt.preventDefault();
    const element = evt.target;
    document.getElementById(element.dataset.toremove).remove();
  }
}
