import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["datetimePicker"];

  handleSpecificDatetime(ev) {
    if (ev.srcElement.value == "due_datetime") {
      this.datetimePickerTarget.classList.remove("visually-hidden");
    } else {
      this.datetimePickerTarget.classList.add("visually-hidden");
    }
  }
}
